import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { useEffect, useState } from "react";
import type { GroupBase, OptionsOrGroups } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { getSocietyProfileEducationInstanceContract } from "shared/api/contracts/society/societyId/profiles/profileId/education/instance";
import { EducationInstanceView } from "shared/mappers/database/education/education-instance";
import { SessionView } from "shared/mappers/database/session/session";

export type SurveyJSProfileEducationProps = {
  session: SessionView | null;
  baseUrl?: string;
  handleChanged: ({
    educationInstance,
    hoursToUse,
  }: {
    educationInstance: EducationInstanceView | null;
    hoursToUse: number | null;
  }) => void;
  existingValue?: EducationInstanceView;
  hoursRequired?: boolean;
};

export type OptionsValuesSurveyJsProfileEducation = {
  value: EducationInstanceView;
  label: string;
} | null;

const SurveyJSProfileEducation = ({
  session,
  handleChanged,
  existingValue,
  hoursRequired,
}: SurveyJSProfileEducationProps) => {
  const [newQuestionValue, setNewQuestionValue] =
    useState<OptionsValuesSurveyJsProfileEducation | null>(null);
  const [hoursToUse, setHoursToUse] = useState<number | null>(null);

  useEffect(() => {
    if (existingValue && !newQuestionValue) {
      setNewQuestionValue({
        value: existingValue,
        label:
          existingValue.overrideName ??
          existingValue.educationDefinition?.educationName ??
          "",
      });
      // setHours(existingValue.hoursEarned ?? null);
    }
  }, [existingValue, newQuestionValue]);

  const fetchProfileEducationInstances = async (
    inputValue: string,
    loadedOptions: OptionsOrGroups<
      OptionsValuesSurveyJsProfileEducation,
      GroupBase<OptionsValuesSurveyJsProfileEducation>
    >,
    page = 1,
  ) => {
    const pageSize = 25;
    const response = await newApiRequest(
      getSocietyProfileEducationInstanceContract,
      {
        params: {
          societyId: Number(session?.societyId) ?? "",
          profileId: Number(session?.profileId) ?? "",
        },
      },
      { text: inputValue },
      { page, pageSize },
    );

    const options = response.results.map((item) => ({
      value: item,
      label: item.overrideName ?? item.educationDefinition?.educationName ?? "",
    }));

    const hasMore = response.page < Math.ceil(response.totalResults / pageSize);

    return { options, hasMore, additional: page + 1 };
  };

  return (
    <div className="flex flex-col gap-y-2">
      <AsyncPaginate
        loadOptions={fetchProfileEducationInstances}
        isDisabled={false}
        debounceTimeout={500}
        placeholder="Search for a profile education instance"
        value={newQuestionValue}
        onChange={(option: OptionsValuesSurveyJsProfileEducation) => {
          if (!option) return;

          setNewQuestionValue(option);
          setHoursToUse(option.value.hoursEarned ?? null);
          handleChanged({
            educationInstance: option.value,
            hoursToUse: option.value.hoursEarned ?? null,
          });
        }}
        additional={1}
      />
      {newQuestionValue && (
        <>
          <div>
            Enter how many hours to use (max:
            {newQuestionValue.value.hoursEarned})
          </div>
          <input
            type="number"
            placeholder={`Hours to use (max: ${newQuestionValue.value.hoursEarned})`}
            max={newQuestionValue.value.hoursEarned ?? 0}
            value={hoursToUse ?? undefined}
            onChange={(event) => {
              if (
                newQuestionValue.value.hoursEarned &&
                +event.target.value > +newQuestionValue.value.hoursEarned
              ) {
                event.target.value = String(newQuestionValue.value.hoursEarned);
              }
              setHoursToUse(Number(event.target.value));
              handleChanged({
                educationInstance: newQuestionValue.value,
                hoursToUse: Number(event.target.value),
              });
            }}
            className="sd-input sd-text"
            required={hoursRequired ?? true}
          />
        </>
      )}
    </div>
  );
};

export default SurveyJSProfileEducation;
