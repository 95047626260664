import { ProductView } from "shared/mappers/database/accounting/product/product";
import PillarFormProfileSelect, {
  PillarFormProfileSelectProps,
} from "shared/components/pillar-form/components/PillarFormProfileSelect";

export type PillarFormProductSelectProps<FormType> = Omit<
  PillarFormProfileSelectProps<ProductView, number, FormType>,
  "labelCallback" | "valueCallback"
>;

const PillarFormProductSelect = <FormType,>({
  name,
  testid,
  ...props
}: PillarFormProductSelectProps<FormType>) => {
  return (
    <PillarFormProfileSelect<ProductView, number, FormType>
      name={name}
      cacheKey={["products"]}
      testid={`${testid}-modal-form-product`}
      additionalClasses="mb-1.5"
      {...props}
      labelCallback={(product) => {
        return product.name!;
      }}
      valueCallback={(product) => {
        return product.id;
      }}
    />
  );
};

export default PillarFormProductSelect;
