import { Prisma } from "@prisma/client";
import { SurveyJSViewMode } from "admin/src/constants";
import { WorkflowInstanceStopped } from "admin/src/server/bll/workflow/instance/WorkflowInstanceRunner";
import { SurveyPreviewProps } from "admin/src/ui/components/surveyJS/preview/SurveyPreview";
import { degree_question } from "admin/src/ui/components/surveyJS/shared/degree_question";
import { ProfileEducationMultipleQuestion } from "admin/src/ui/components/surveyJS/shared/education/ProfileEducationMultipleQuestion";
import { RegisterProfileEducationQuestionComponent } from "admin/src/ui/components/surveyJS/shared/education/ProfileEducationQuestionComponent";
import { fileUploadSetup } from "admin/src/ui/components/surveyJS/shared/fileUploadSetup";
import { profileInstitutionTypeQuestion } from "admin/src/ui/components/surveyJS/shared/institution-type/question";
import { login_details_question } from "admin/src/ui/components/surveyJS/shared/login_details_question";

import { RegisterObfuscateQuestionComponent } from "admin/src/ui/components/surveyJS/shared/ObfuscateQuestionComponent";
import { onTextMarkdownApplyHtmlToSurveyContents } from "admin/src/ui/components/surveyJS/shared/onTextMarkdownApplyHtmlToSurveyContents";
import { pillar_choice } from "admin/src/ui/components/surveyJS/shared/pillar_choice";
import { RegisterPillarChoiceDropdownComponent } from "admin/src/ui/components/surveyJS/shared/PillarChoiceDropdownComponent";
import { ProductsSurveyJSQuestion } from "admin/src/ui/components/surveyJS/shared/products/ProductsSurveyJSQuestion";
import { profileAddressContactInformationQuestion } from "admin/src/ui/components/surveyJS/shared/profile-address-contact-information/question";
import { ProfileSearchMultipleQuestion } from "admin/src/ui/components/surveyJS/shared/profile-search/ProfileSearchMultipleQuestion";
import { RegisterProfileSearchQuestionComponent } from "admin/src/ui/components/surveyJS/shared/profile-search/ProfileSearchQuestionComponent";
import { profileSearchValidationExpressions } from "admin/src/ui/components/surveyJS/shared/profile-search/profileSearchValidationExpressions";
import { surveyInitProfileSearchQuestion } from "admin/src/ui/components/surveyJS/shared/profile-search/surveyInitProfileSearchQuestion";
import { profile_type_question } from "admin/src/ui/components/surveyJS/shared/profile_type";
import { registerProperties } from "admin/src/ui/components/surveyJS/shared/properties/registerProperties";
import { RegisterReactTextboxComponent } from "admin/src/ui/components/surveyJS/shared/ReactTextboxComponent";
import { setupChoicesFromPillar } from "admin/src/ui/components/surveyJS/shared/setupChoicesFromPillar";

import { FormDesign } from "admin/src/ui/components/surveyJS/types/data";
import debounce from "lodash.debounce";
import {
  CompleteEvent,
  Model,
  SurveyModel,
  ValueChangingEvent,
} from "survey-core";
import { AdditionalInfoSubmission } from "../types/survey-preview-types";

export type InitializeSurveyModelProps = {
  session: SurveyPreviewProps["session"];
  baseUrl?: string;
  onCompleteCallback?: (
    sender: SurveyModel,
    options: CompleteEvent,
  ) => Promise<WorkflowInstanceStopped>;
  autoSaveChangesCallback?: (formData: Prisma.JsonValue) => Promise<void>;
  cancelAutoSave?: () => Promise<void>;
  formDesign: FormDesign;
  formData?: Prisma.JsonValue;
  worklflowId?: number;
  isReadOnly?: boolean;
  additionalInfo?: AdditionalInfoSubmission;
  overrideCompleteButtonText?: string;
  overrideCompletePageHTML?: string;
};
export const initializeSurveyModel = async ({
  session,
  formDesign,
  formData,
  isReadOnly,
  baseUrl,
  cancelAutoSave,
  onCompleteCallback,
  autoSaveChangesCallback,
  overrideCompleteButtonText,
}: InitializeSurveyModelProps) => {
  registerProperties(session.society!);
  await Promise.all([
    pillar_choice("cospeaker_roles", session),
    pillar_choice("research_type", session),
    pillar_choice("primary_category", session),
    pillar_choice("expertise", session),
    pillar_choice("secondary_category", session),
    login_details_question(session),
    ProductsSurveyJSQuestion({ session }),
    RegisterProfileSearchQuestionComponent(session, baseUrl!),
    profileInstitutionTypeQuestion(session.society?.institutionType ?? []),
    profile_type_question(),
    degree_question(),
    ProfileSearchMultipleQuestion({ session }),
    RegisterObfuscateQuestionComponent(),
    RegisterProfileEducationQuestionComponent(session, baseUrl!),
    ProfileEducationMultipleQuestion({ session }),
  ]);
  RegisterReactTextboxComponent();

  RegisterPillarChoiceDropdownComponent(session);
  //This is a dependent question (on emailOption), and must be run after the concurrent promises.all
  await profileAddressContactInformationQuestion({
    session,
  });
  const model = new Model(formDesign);
  onTextMarkdownApplyHtmlToSurveyContents({ surveyModel: model });
  profileSearchValidationExpressions();
  surveyInitProfileSearchQuestion({
    surveyModel: model,
    session,
  });

  if (overrideCompleteButtonText !== undefined) {
    model.completeText = overrideCompleteButtonText;
  }

  //Tags if Admin all tags, else only current profile tags
  const tags = session.adminMode
    ? session.society?.tags?.map((tag) => tag.tagName) ?? []
    : session.profile?.tags?.map((tag) => tag.tagName);
  model.setVariable("societyTags", tags);

  //IgnoreValidation admin mode
  if (session.adminMode) {
    model.ignoreValidation = true;
  }

  if (session.society) {
    setupChoicesFromPillar({
      society: session.society!,
      surveyModel: model,
      baseUrl: baseUrl,
    });
  }

  if (session.society?.societyId) {
    await fileUploadSetup({
      societyId: Number(session.society.societyId),
      profileId: session.profile?.id,
      model,
      baseUrl: baseUrl,
    });
  }

  model.clearInvisibleValues = "none";

  if (isReadOnly) {
    model.mode = SurveyJSViewMode.Read_Only;
  }
  if (model && formData && Object.keys(formData as object).length > 0) {
    model.mergeData(formData);
  }

  model.onValidateQuestion.add((sender: SurveyModel, options) => {
    const question = options.question;
    const wordLimit = question.getPropertyValue("wordLimit");
    const withSpaces = question.getPropertyValue("withSpaces");
    if (!wordLimit) {
      return;
    } else {
      const text = question.value;
      if (text) {
        let wordCount;
        if (withSpaces) {
          wordCount =
            text.split(/\s+/).length - 1 + (text.match(/\s/g) || []).length;
        } else {
          wordCount = text.trim().split(/\s+/).length;
        }
        if (wordCount > wordLimit) {
          options.error = `Word limit exceeded. Maximum ${wordLimit} words allowed. You have used ${wordCount} words ${
            withSpaces && "including the spaces"
          }.`;
        }
      }
    }
  });

  // const autoSaveChangesCallback = autoSaveChanges ?? (() => Promise.resolve());
  const debouncedAutoSaveChanges = debounce(
    autoSaveChangesCallback ?? (() => Promise.resolve()),
    3000,
    {
      leading: false,
      trailing: true,
      maxWait: 30000,
    },
  );
  if (autoSaveChangesCallback) {
    model.textUpdateMode = "onTyping";
    model.onValueChanging.add(
      async (sender: SurveyModel, options: ValueChangingEvent) => {
        if (sender.completedState !== "saving") {
          try {
            await debouncedAutoSaveChanges(sender.data);
          } catch (error) {
            console.error(error);
          }
        }
        return options.value;
      },
    );
  }
  model.onComplete.add(async (sender: SurveyModel, options: CompleteEvent) => {
    const { clearSaveMessages, showSaveInProgress, showSaveError } = options;
    clearSaveMessages();
    showSaveInProgress("Saving your response");
    try {
      if (cancelAutoSave) {
        //The cancelAutoSave can be passed in to potentially cancel an in-progress auto-save
        await cancelAutoSave();
      }
      //This stops the auto-save from running after the form is submitted
      debouncedAutoSaveChanges.cancel();
      //This flushes the auto-save queue, so that any pending auto-saves are run or potentially finished before the form is submitted
      await debouncedAutoSaveChanges.flush();

      if (onCompleteCallback !== undefined) {
        await onCompleteCallback(sender, options);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        showSaveError(error.message);
      }
    }
  });
  return model;
};
