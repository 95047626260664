import PageComponent from "hub/src/components/common/PageComponent";
import { SocietyLogo } from "hub/src/components/common/SocietyLogo";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { Link } from "react-router-dom";
import PillarForm from "shared/components/pillar-form/PillarForm";
import { SocietyAuthBodyInput } from "shared/api/types/society/[societyId]/auth";
import { FormikHelpers } from "formik";
import * as Yup from "yup";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { useState } from "react";
const LoginPage = () => {
  const session = useHubSessionContext();
  const device = useDeviceInfo();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (
    values: Required<SocietyAuthBodyInput>,
    formikHelpers?: FormikHelpers<Required<SocietyAuthBodyInput>> | undefined,
  ) => {
    setFormSubmitted(true);
    try {
      await session.login(values);
    } catch (error: any) {
      setFormSubmitted(false);
      formikHelpers?.setStatus({
        error: "Check your email and password and ensure they are correct.",
      });
    }
  };

  if (
    !formSubmitted &&
    (!session || session.sessionLoading || session.societyUserId)
  ) {
    return <LoadingPage />;
  }
  return (
    <PageComponent>
      <PageComponent.Content className="container-light h-full w-full">
        <div
          className="h-full w-full max-w-1/4 min-w-9xl m-auto
          flex flex-col justify-center items-center pl-3 pr-3
          phone:min-w-full phone:max-w-none"
        >
          {/* TODO: This is a placeholder for the society logo, which we currently
        only have access to after authentication. We will need to either fetch
        the society info prior to auth or package the logos with the build
        itself per-society. If we opt not to package with the build, we may not
        be able to include it in the splash screen, but can still include it
        here. Once we get this out of placeholder, these styles should be in common
        or component tokens. The only inline should be the layout utility classes. */}
          {session.society?.societySettingsPublic?.societyLogoLoginUrl ? (
            <SocietyLogo className="w-[100px] h-[100px] mb-2 !border-society-400" />
          ) : (
            <div className="w-12.5 h-12.5 mb-7 rounded-full bg-primary flex justify-center items-center">
              <span className="text-5xl text-extrabold text-neutral-light">
                {session.society?.abbreviation}
              </span>
            </div>
          )}

          <h2 className="mb-4.5">Log In</h2>
          {/* TODO: Replace this with PillarForm */}
          <PillarForm<Required<SocietyAuthBodyInput>>
            id="login-form"
            handleSubmit={handleSubmit}
            className="flex flex-col w-full"
            yupValidation={Yup.object().shape({
              email: Yup.string().email().required().label("Email"),
              password: Yup.string().required(),
            })}
          >
            <PillarForm.EmailInput
              name="email"
              autoComplete="email"
              label={"Email address"}
              required
              data-testid={"login-form-email"}
            />
            <PillarForm.PasswordInput
              name="password"
              autoComplete="password"
              label={"Password"}
              required
              data-testid={"login-form-password"}
            />
            <PillarForm.LoadingSubmitButton
              className="button-regular-general-filled w-full mt-4"
              testid="login-form-submit"
            >
              Log In
            </PillarForm.LoadingSubmitButton>

            {device.isWeb && (
              <PillarForm.Button className="button-regular-general-unfilled mt-4 group">
                <Link to="/account/new">
                  {"Don't have an account? "}
                  <span data-testid="signup-link" className="font-bold">
                    Sign Up!
                  </span>
                </Link>
              </PillarForm.Button>
            )}

            <PillarForm.Button className="button-regular-general-unfilled mt-4 group">
              <Link to="/account/recover">
                {"Forgot Login? "}

                <span className="font-bold">Click to recover!</span>
              </Link>
            </PillarForm.Button>
          </PillarForm>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default LoginPage;
