import { Field, FormikValues, useFormikContext } from "formik";

import React from "react";

import PillarFormInputWrapper, {
  PillarFormInputWrapperProps,
} from "./PillarFormInputWrapper";

export type PillarFormCheckboxProps = PillarFormInputWrapperProps & {
  name: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PillarFormCheckbox = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  ...props
}: PillarFormCheckboxProps) => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <PillarFormInputWrapper
      name={name}
      labelClassName={labelClassName}
      additionalClasses={additionalClasses}
      label={label}
    >
      <Field
        type="checkbox"
        className="h-2 w-2 rounded"
        checked={values[name]}
        name={name}
        id={name}
        {...props}
      />
    </PillarFormInputWrapper>
  );
};

export default PillarFormCheckbox;
