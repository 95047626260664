import PillarFormCheckbox from "./PillarFormCheckbox";
import PillarFormAddressInput from "./PillarFormAddressInput";
import PillarFormLoadingButton from "./PillarFormLoadingButton";
import PillarFormRadio from "./PillarFormRadio";
import PillarFormTextInput from "./PillarFormTextInput";
import PillarFormButton from "./PillarFormButton";
import PillarFormExpirationDateInput from "./PillarFormExpirationDateInput";
import PillarFormLoadingSubmitButton from "./PillarFormLoadingSubmitButton";
import PillarFormSelectMenu from "./PillarFormSelectMenu";
import PillarFormTimeInput from "./PillarFormTimeInput";
import PillarFormFieldTextArray from "./PillarFormFieldTextArray";
import PillarFormNumberInput from "./PillarFormNumberInput";
import PillarFormSelectMenuPaginated from "./PillarFormSelectMenuPaginated";
import PillarFormColorInput from "./PillarFormColorInput";
import PillarFormPasswordInput from "./PillarFormPasswordInput";
import PillarFormSubmitButton from "./PillarFormSubmitButton";
import PillarFormUSDInput from "./PillarFormUSDInput";
import PillarFormCombobox from "./PillarFormCombobox";
import PillarFormHexColorInput from "./PillarFormHexColorInput";
import PillarFormPercentageInput from "./PillarFormPercentageInput";
import PillarFormSwitch from "./PillarFormSwitch";
import PillarFormDateTimeInput from "./PillarFormDateTimeInput";
import PillarFormIconPicker from "./PillarFormIconPicker";
import PillarFormDynamicArray from "./PillarFormDynamicArray";
import PillarFormInputWrapper from "./PillarFormInputWrapper";
import PillarFormProductSelect from "./PillarFormProductSelect";
import PillarFormEmailInput from "./PillarFormEmailInput";
import PillarFormJSONInput from "./PillarFormJSONInput";
import PillarFormProfileSelect from "./PillarFormProfileSelect";
import PillarFormTextAreaInput from "./PillarFormTextAreaInput";

export const populatePillarFormComponents = (form: any) => {
  form.Checkbox = PillarFormCheckbox;
  form.AddressInput = PillarFormAddressInput;
  form.LoadingButton = PillarFormLoadingButton;
  form.Radio = PillarFormRadio;
  form.TextInput = PillarFormTextInput;
  form.Button = PillarFormButton;
  form.ExpirationDateInput = PillarFormExpirationDateInput;
  form.LoadingSubmitButton = PillarFormLoadingSubmitButton;
  form.SelectMenu = PillarFormSelectMenu;
  form.TimeInput = PillarFormTimeInput;
  form.FieldTextArray = PillarFormFieldTextArray;
  form.NumberInput = PillarFormNumberInput;
  form.SelectMenuPaginated = PillarFormSelectMenuPaginated;
  form.ColorInput = PillarFormColorInput;
  form.PasswordInput = PillarFormPasswordInput;
  form.SubmitButton = PillarFormSubmitButton;
  form.USDInput = PillarFormUSDInput;
  form.Combobox = PillarFormCombobox;
  form.HexColorInput = PillarFormHexColorInput;
  form.PercentageInput = PillarFormPercentageInput;
  form.Switch = PillarFormSwitch;
  form.DateTimeInput = PillarFormDateTimeInput;
  form.IconPicker = PillarFormIconPicker;
  form.DynamicArray = PillarFormDynamicArray;
  form.InputWrapper = PillarFormInputWrapper;
  form.ProductSelect = PillarFormProductSelect;
  form.EmailInput = PillarFormEmailInput;
  form.JSONInput = PillarFormJSONInput;
  form.ProfileSelect = PillarFormProfileSelect;
  form.TextAreaInput = PillarFormTextAreaInput;
};
