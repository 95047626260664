import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import * as Yup from "yup";
import { AnyObject as yupAnyObject } from "yup/lib/types";
import PillarFormCheckbox from "shared/components/pillar-form/components/PillarFormCheckbox";
import PillarFormNumberInput from "shared/components/pillar-form/components/PillarFormNumberInput";
import PillarFormSelectMenu from "shared/components/pillar-form/components/PillarFormSelectMenu";
import PillarFormSwitch from "shared/components/pillar-form/components/PillarFormSwitch";
import PillarFormTextAreaInput from "shared/components/pillar-form/components/PillarFormTextAreaInput";
import PillarFormTextInput from "shared/components/pillar-form/components/PillarFormTextInput";
import PillarFormUSDInput from "shared/components/pillar-form/components/PillarFormUSDInput";
import { flattenChildComponents } from "admin/src/utils/helpers/flatten-child-components";
import PillarFormTypes from "./components/PillarFormTypes";
import { populatePillarFormComponents } from "./components/populatePillarFormComponents";
import PillarFormAddressInput from "./components/PillarFormAddressInput";
import PillarFormButton from "./components/PillarFormButton";
import PillarFormColorInput from "./components/PillarFormColorInput";
import PillarFormCombobox from "./components/PillarFormCombobox";
import PillarFormDateTimeInput from "./components/PillarFormDateTimeInput";
import PillarFormDynamicArray from "./components/PillarFormDynamicArray";
import PillarFormEmailInput from "./components/PillarFormEmailInput";
import PillarFormExpirationDateInput from "./components/PillarFormExpirationDateInput";
import PillarFormFieldTextArray from "./components/PillarFormFieldTextArray";
import PillarFormFileSelector from "@parthenon-management/pillar-admin/src/ui/components/common/form/PillarFormFileSelector";
import PillarFormHexColorInput from "./components/PillarFormHexColorInput";
import PillarFormIconPicker from "./components/PillarFormIconPicker";
import PillarFormInputWrapper from "./components/PillarFormInputWrapper";
import PillarFormJSONInput from "./components/PillarFormJSONInput";
import PillarFormLoadingButton from "./components/PillarFormLoadingButton";
import PillarFormLoadingSubmitButton from "./components/PillarFormLoadingSubmitButton";
import PillarFormPasswordInput from "./components/PillarFormPasswordInput";
import PillarFormPercentageInput from "./components/PillarFormPercentageInput";
import PillarFormPermissionTagInput from "@parthenon-management/pillar-admin/src/ui/components/common/form/PillarFormPermissionTagInput";
import PillarFormProductSelect from "./components/PillarFormProductSelect";
import PillarFormProfileSelect from "./components/PillarFormProfileSelect";
import PillarFormRadio from "./components/PillarFormRadio";
import PillarFormSelectMenuPaginated from "./components/PillarFormSelectMenuPaginated";
import PillarFormSubmitButton from "./components/PillarFormSubmitButton";
import PillarFormTagInput from "@parthenon-management/pillar-admin/src/ui/components/common/form/PillarFormTagInput";
import PillarFormTimeInput from "./components/PillarFormTimeInput";

type PillarFormProps<GenericFormikValues> = ComponentPropsWithoutRef<"form"> & {
  children: React.ReactNode;
  handleSubmit: (
    values: GenericFormikValues,
    formikHelpers?: FormikHelpers<GenericFormikValues>
  ) => Promise<void>;
  handleChanged?: (values: GenericFormikValues) => void;
  overrideInitialValues?: Partial<GenericFormikValues> | null;
  yupValidation?: yupAnyObject;
};

const PillarFormToBeForwarded = <GenericFormikValues extends FormikValues>(
  {
    children,
    handleSubmit,
    handleChanged,
    overrideInitialValues,
    yupValidation,
    ...props
  }: PillarFormProps<GenericFormikValues>,
  ref: React.ForwardedRef<FormikProps<GenericFormikValues> | undefined>
) => {
  const [formValues, setFormValues] = useState<
    GenericFormikValues | undefined
  >();

  useEffect(() => {
    if (!formValues || !handleChanged) return;
    handleChanged(formValues);
  }, [formValues]);

  useEffect(() => {
    if (overrideInitialValues) {
      setFormValues(overrideInitialValues as GenericFormikValues);
    }
  }, [overrideInitialValues]);

  const initialValues: GenericFormikValues = (overrideInitialValues ??
    {}) as GenericFormikValues;
  const flattenedChildren = flattenChildComponents(children);
  const yupSchema = yupValidation ?? Yup.object({});

  flattenedChildren.forEach((child) => {
    if (!overrideInitialValues) {
      if (
        child.type === PillarFormTextInput ||
        child.type === PillarFormTextAreaInput ||
        child.type === PillarFormNumberInput ||
        child.type === PillarFormUSDInput
      ) {
        initialValues[child.props.name as keyof GenericFormikValues] =
          child.props.initalValue ?? "";
      }
      if (
        child.type === PillarFormSwitch ||
        child.type === PillarFormCheckbox
      ) {
        initialValues[child.props.name as keyof GenericFormikValues] =
          child.props.initalValue ?? false;
      }
      if (child.type === PillarFormSelectMenu) {
        initialValues[child.props.name as keyof GenericFormikValues] =
          child.props.initialOption?.value ?? undefined;
      }
    }
  });

  // Custom validate function to log validation errors
  const validate = (values: GenericFormikValues) => {
    try {
      // Use Yup schema to validate the form values
      yupSchema.validateSync(values, { abortEarly: false });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Log errors in development mode
        if (process.env.NODE_ENV === "development") {
          console.log("Yup validation errors:", error.inner);
        }

        // Transform Yup errors to Formik-compatible error object
        const formikErrors = error.inner.reduce(
          (acc: { [key: string]: string }, err) => {
            if (err.path) {
              acc[err.path] = err.message;
            }
            return acc;
          },
          {}
        );
        return formikErrors;
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (
        values: GenericFormikValues,
        formikHelpers: FormikHelpers<GenericFormikValues>
      ) => {
        try {
          await handleSubmit(values, formikHelpers);
        } catch (error: any) {
          formikHelpers.setStatus({ error: error.message });
        }
      }}
      enableReinitialize={true}
      innerRef={(formikActions) => {
        if (formikActions) {
          setFormValues(formikActions.values);
          if (formikActions.isSubmitting) {
            const errorFieldsArray = Object.keys(formikActions.errors);
            if (
              !errorFieldsArray.every(
                (errorFieldName) => !!formikActions.touched[errorFieldName]
              )
            ) {
              errorFieldsArray.map((fieldName: string) =>
                formikActions.setFieldTouched(fieldName)
              );
            }
          }
        }
        if (ref) {
          if (typeof ref === "function") {
            ref(formikActions);
          } else {
            ref.current = formikActions;
          }
        }
      }}
      validationSchema={yupSchema}
      validate={validate} // Add the custom validate function
    >
      <Form {...props}>{children}</Form>
    </Formik>
  );
};

type PillarFormType = {
  <GenericFormikValues extends FormikValues>(
    props: PillarFormProps<GenericFormikValues> & React.RefAttributes<FormikProps<GenericFormikValues>>
  ): React.ReactElement;
} & typeof PillarFormTypes;

const PillarForm = React.forwardRef(
  PillarFormToBeForwarded
) as unknown as PillarFormType;

populatePillarFormComponents(PillarForm);

export default PillarForm;
