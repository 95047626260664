import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://tabler.io/icons/icon/plus
const Plus = ({ className, ...props }: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames = "icon icon-tabler icon-tabler-plus h-2.5 w-2.5";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
  );
};

export default Plus;
