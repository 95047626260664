import { FormikValues, useFormikContext } from "formik";
import { get, set } from "lodash";
import { ComponentPropsWithoutRef } from "react";
import Plus from "shared/components/icons/sign/Plus";
import { PillarFormInputWrapperProps } from "shared/components/pillar-form/components/PillarFormInputWrapper";
import PillarFormTextInput from "shared/components/pillar-form/components/PillarFormTextInput";
import PillarFormButton from "./PillarFormButton";

export type PillarFormFieldTextArrayProps = PillarFormInputWrapperProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

const PillarFormFieldTextArray = <T,>({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  testid,
  ...props
}: PillarFormFieldTextArrayProps) => {
  const { values, setValues } = useFormikContext<FormikValues>();
  const value = get(values, name, []);

  const updateValues = (newValue: string[]) => {
    const updatedValues = set({ ...values }, name, newValue);
    setValues(updatedValues);
  };

  return (
    <>
      {value?.map((item: string, index: number) => {
        return (
          <div
            className={`flex flex-row space-x-1 ${className}`}
            key={`${name}.${index}`}
          >
            <PillarFormTextInput
              testid={testid ? `${testid}-text-${index}` : undefined}
              name={`${name}.${index}`}
              labelClassName={labelClassName}
              label={label}
              additionalClasses={`${additionalClasses ?? ""} w-full`}
              {...props}
            />
            <div className="flex items-end">
              <PillarFormButton
                testid={testid ? `${testid}-remove-button-${index}` : undefined}
                onClick={() => {
                  const newValue = [
                    ...value.slice(0, index),
                    ...value.slice(index + 1),
                  ];
                  updateValues(newValue);
                }}
                className="button-regular-destructive-filled h-5"
              >
                Remove
              </PillarFormButton>
            </div>
          </div>
        );
      })}
      <div className="flex">
        <PillarFormButton
          testid={testid ? `${testid}-add-button` : undefined}
          onClick={() => {
            const newValue = [...value, ""];
            updateValues(newValue);
          }}
          className="button-regular-general-filled flex"
        >
          <Plus className="h-3 w-3 stroke-neutral-light" />
        </PillarFormButton>
      </div>
    </>
  );
};

export default PillarFormFieldTextArray;
