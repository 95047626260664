import { AddressBodyInputFullAddress } from "admin/src/ui/types/address";

import { INVOICE_STATUS } from "hub/src/appConstants";
import { AxiosError } from "axios";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { Form, Formik, useFormikContext } from "formik";
import useHubSessionContext from "hub/src/hooks/useHubSessionContext";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AuthorizeAcceptSuite } from "shared/AuthorizeAcceptSuite";

import { paymentFormvalidationSchema } from "hub/src/yupValidationSchema/payment-form-schema";
import { AddressForm } from "./AddressForm";
import CheckPaymentForm from "./CheckPaymentForm";
import PaymentForm from "./PaymentForm";
import { newApiRequest } from "admin/src/ui/api-callouts/utils";
import { postSocietyProfileAccountingInvoicePaymentContract } from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId/payment";
import { useQuery } from "@tanstack/react-query";
import { getSocietyProfileAccountingInvoiceIdContract } from "shared/api/contracts/society/societyId/profiles/profileId/accounting/invoice/invoiceId";
import PillarForm from "shared/components/pillar-form/PillarForm";

const defaultCardData = {
  cardNumber: "",
  cardCode: "",
  zip: "",
  fullName: "",
  month: "",
  year: "",
};

const enum PaymentType {
  CHECK = "check",
  CARD = "card",
}

interface CheckoutForm {
  cardData: AuthorizeAcceptSuite.CardData;
  shippingAddressDat?: AddressBodyInputFullAddress;
  billingAddressData: AddressBodyInputFullAddress;
  isSameAsBilling: boolean;
}

type CheckoutInformationFormProps = ComponentPropsWithoutRef<"form">;

const CheckoutInformationForm = ({
  className,
  ...props
}: CheckoutInformationFormProps) => {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const history = useHistory();
  const session = useHubSessionContext();

  const { setFieldValue, values } = useFormikContext<CheckoutForm>();
  const invoiceQuery = useQuery(
    [`invoiceId-${invoiceId}`],
    async () =>
      await newApiRequest(getSocietyProfileAccountingInvoiceIdContract, {
        params: {
          societyId: session.societyId!,
          profileId: session.profileId!,
          invoiceId: Number(invoiceId),
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const [cardDetails, setCardDetails] = useState<AuthorizeAcceptSuite.CardData>(
    {
      cardNumber: "",
      month: "",
      year: "",
      cardCode: "",
      zip: "",
    },
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CARD);
  const [billingAddressData, setBillingAddressData] =
    useState<AddressBodyInputFullAddress>({
      line1: "",
      line2: "",
      line3: "",
      city: "",
      state: "",
      country: "",
      region: "",
      postalCode: "",
    });
  const [shippingAddressData, setShippingAddressData] =
    useState<AddressBodyInputFullAddress>({
      line1: "",
      line2: "",
      line3: "",
      city: "",
      state: "",
      country: "",
      region: "",
      postalCode: "",
    });

  useEffect(() => {
    const fetchAuthorizeNetScript = async () => {
      const script = document.createElement("script");
      script.src =
        session.society?.societySettingsPublic?.authorizeNetAcceptSuiteUrl ??
        "";
      script.async = true;
      document.body.appendChild(script);
    };

    fetchAuthorizeNetScript();
  }, []);

  const handleSubmit = async () => {
    try {
      let authorizeNetResponse:
        | AuthorizeAcceptSuite.DispatchDataResponse
        | undefined;
      if (invoiceQuery?.data?.total ?? 0 > 0) {
        const authData = new AuthorizeAcceptSuite.AuthData(
          session.society!.societySettingsPublic!.authorizeNetPublicClientId!,
          session.society!.societySettingsPublic!.authorizeNetMerchantNameLoginId!,
        );

        const secureData = new AuthorizeAcceptSuite.SecureData(
          authData,
          cardDetails,
        );

        const paymentProcessor = new AuthorizeAcceptSuite.PaymentProcessor(
          secureData,
        );

        authorizeNetResponse = await paymentProcessor.getResponse();
        if (authorizeNetResponse.messages.resultCode !== "Ok") {
          setErrorMessage(
            authorizeNetResponse.messages.message.map((m) => m.text).join(", "),
          );
          return;
        }
        billingAddressData.postalCode = cardDetails.zip ?? "";
        shippingAddressData.postalCode = cardDetails.zip ?? "";
      }

      try {
        await newApiRequest(
          postSocietyProfileAccountingInvoicePaymentContract,
          {
            params: {
              societyId: session.societyId!,
              profileId: session.profileId!,
              invoiceId: Number(invoiceId),
            },
            body: {
              amount: invoiceQuery?.data?.total ?? 0,
              shippingAddress: values.isSameAsBilling
                ? billingAddressData
                : shippingAddressData,
              billingAddress: billingAddressData,
              authorizeAcceptSuite: authorizeNetResponse,
            },
          },
        );

        //This is intentionally not awaited so that the user can be redirected to the complete page while it refreshes.
        session.refreshSession();

        history.replace("/overview");
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          setErrorMessage(error.response.data.error);
        }
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while processing your payment. Please try again later.",
      );
    }
  };

  const handleCardDetails = (details: {
    cardNumber: number;
    expirationDate: string;
    cvc: number;
    zip: number;
  }) => {
    setCardDetails({
      cardNumber: details.cardNumber?.toString(),
      month: details.expirationDate?.split("/")[0],
      year: details.expirationDate?.split("/")[1],
      cardCode: details.cvc?.toString(),
      zip: details.zip?.toString(),
      fullName: "Test User",
    });
  };

  if (invoiceQuery.isLoading) {
    return <LoadingPage />;
  }
  const payBtnIsDisabled =
    invoiceQuery?.data?.status === INVOICE_STATUS.PAID ||
    (invoiceQuery?.data?.total ?? 0) === 0;
  return (
    <div className="w-full">
      <Form
        className={`flex flex-col space-y-2 p-2 pt-4.5 ${className ?? ""}`}
        {...props}
      >
        {/* <CheckoutDonation /> */}
        {(invoiceQuery.data?.total ?? 0) > 0 ? (
          <>
            {session.society?.societySettingsPublic?.hubPaymentTypes &&
              session.society?.societySettingsPublic?.hubPaymentTypes.check && (
                <div className="flex justify-center">
                  <button
                    type="button"
                    className={`mx-2 w-fit whitespace-nowrap ${
                      paymentType == PaymentType.CARD
                        ? "button-regular-general-filled"
                        : "text-neutral-mid-600 text-base"
                    }`}
                    onClick={() => setPaymentType(PaymentType.CARD)}
                  >
                    Card
                  </button>
                  <button
                    type="button"
                    className={`mx-2 w-fit whitespace-nowrap ${
                      paymentType == PaymentType.CHECK
                        ? "button-regular-general-filled"
                        : "text-neutral-mid-600 text-base"
                    }`}
                    onClick={() => setPaymentType(PaymentType.CHECK)}
                  >
                    Check
                  </button>
                </div>
              )}
            {paymentType == PaymentType.CARD && (
              <>
                <div className="py-4">
                  <AddressForm
                    addressType={"Billing"}
                    setAddressState={setBillingAddressData}
                    addressState={billingAddressData}
                  />
                </div>
                <section aria-labelledby="billing-heading">
                  <div className="flex items-center space-x-1 pb-6">
                    <input
                      id="isSameAsBilling"
                      name="isSameAsBilling"
                      type="checkbox"
                      checked={values.isSameAsBilling}
                      onChange={(e) =>
                        setFieldValue("isSameAsBilling", e.target.checked)
                      }
                    />
                    <label
                      htmlFor="isSameAsBilling"
                      className="text-sm font-medium text-gray-900"
                    >
                      Same as shipping information
                    </label>
                  </div>

                  {!values.isSameAsBilling && (
                    <div className="pb-6">
                      <AddressForm
                        addressType={"Shipping"}
                        addressState={shippingAddressData}
                        setAddressState={setShippingAddressData}
                      />
                    </div>
                  )}
                  <PaymentForm
                    invoiceId={invoiceId}
                    zip={billingAddressData?.postalCode ?? ""}
                    disabled={payBtnIsDisabled}
                    errorMessage={errorMessage}
                    handleCardDetails={handleCardDetails}
                    handleSubmit={handleSubmit}
                  />
                </section>

                {invoiceQuery?.data?.status === INVOICE_STATUS.PAID && (
                  <div className="text-center text-xs text-status-success">
                    Paid
                  </div>
                )}
              </>
            )}
            {paymentType == PaymentType.CHECK && <CheckPaymentForm />}
          </>
        ) : (
          <PillarForm handleSubmit={handleSubmit}>
            <PillarForm.LoadingSubmitButton
              testid="payment-pay-button"
              className="button-regular-general-filled w-full"
            >
              Confirm $0 Payment
            </PillarForm.LoadingSubmitButton>
          </PillarForm>
        )}
      </Form>
    </div>
  );
};

const CheckoutInformationFormHOC = (props: CheckoutInformationFormProps) => {
  return (
    <Formik
      initialValues={{
        cardData: defaultCardData,
        shippingAddressData: {},
        billingAddressData: {},
        isSameAsBilling: false,
      }}
      onSubmit={() => undefined}
      validationSchema={paymentFormvalidationSchema}
      validateOnChange={true}
    >
      <CheckoutInformationForm {...props} />
    </Formik>
  );
};

export default CheckoutInformationFormHOC;
