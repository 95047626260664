import { Dialog, Transition } from "@headlessui/react";

import {
  ComponentPropsWithoutRef,
  forwardRef,
  Fragment,
  useImperativeHandle,
  useState,
} from "react";

// todo: we can depricate this over time
export enum ModalMaxWidth {
  Sm_500 = "max-w-[500px] ",
  Md_700 = "max-w-[700px] ",
  Lg_900 = "max-w-[900px] ",
  Xl_1200 = "max-w-[1200px] ",
  Full = "w-full ",
  Responsive = "w-full max-w-[1500px] mx-[10%] ",
}
export enum ModalMinWidth {
  Sm_500 = "min-w-[500px] ",
  Md_700 = "min-w-[700px] ",
  Lg_900 = "min-w-[900px] ",
  Xl_1200 = "min-w-[1200px] ",
  Full = "min-w-full ",
}
export enum ModalMinHeight {
  Sm_200 = "min-h-[200px] ",
  Md_500 = "min-h-[500px] ",
  Lg_735 = "min-h-[735px] ",
  Full = "min-h-screen ",
}
export enum ModalMaxHeight {
  Sm_200 = "max-h-[200px] ",
  Md_500 = "max-h-[500px] ",
  Lg_735 = "max-h-[735px] ",
  Full = "max-h-screen ",
}

export type PillarModalBodyProps = ComponentPropsWithoutRef<"div"> & {
  testid?: string;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  containerClasses?: string;
  maxW?: ModalMaxWidth;
  minW?: ModalMinWidth;
  maxH?: ModalMaxHeight;
  minH?: ModalMinHeight;
  onClose?: () => void;
  onOpen?: () => void;
};
export type PillarModalHandle<T = any> = {
  open: (selecetedItem?: T) => void;
  close: () => void;
  isOpen: boolean;
  selectedItem?: T;
};
const PillarModalBodyComponent = (
  {
    testid,
    title,
    subtitle,
    children,
    containerClasses,
    maxW,
    minW,
    maxH,
    minH,
    className,
    onOpen,
    onClose,
    ...props
  }: PillarModalBodyProps,
  ref: React.ForwardedRef<PillarModalHandle>
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selecteditem, setSelectedItem] = useState<any>();

  useImperativeHandle(ref, () => ({
    open: (selectedItem?: any) => {
      setSelectedItem(selectedItem);
      setIsOpen(true);
      onOpen?.();
    },
    close: () => {
      setIsOpen(false);
      onClose?.();
    },
    isOpen: isOpen,
    selectedItem: selecteditem,
  }));
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-50 overflow-auto ${containerClasses ?? ""}`}
        onClose={() => {
          setIsOpen(false);
          onClose?.();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-25"
          leave="ease-in duration-200"
          leaveFrom="opacity-25"
          leaveTo="opacity-0"
        >
          <div className="appmodal-body-background fixed inset-0" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="overflow-auto px-3 flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`appmodal-panel-container overflow-auto transform transition-all ${
                  maxW ?? ""
                } ${minW ?? ""} ${minH ?? ""} ${maxH ?? ""} ${className ?? ""}`}
                data-testid={testid}
                {...props}
              >
                <Dialog.Title
                  as="h3"
                  data-testid={`${testid}-title`}
                  className="appmodal-panel-title flex justify-center"
                >
                  {title}
                </Dialog.Title>
                {subtitle && <h6 className="text-subtle mb-2">{subtitle}</h6>}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
const PillarModalBody = forwardRef<PillarModalHandle, PillarModalBodyProps>(
  PillarModalBodyComponent
);

export default PillarModalBody;
